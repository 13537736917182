import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomSelect = ({ label, options, onChange, required }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { t } = useTranslation();

  const toggleOptions = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="select_container">
      {label && (
        <label className="select_label">
          {label} {required && <span className="required">*</span>}
        </label>
      )}
      <div className="select_box" onClick={toggleOptions}>
        <span className="selected_option">
          {selectedOption ||
            t("CompanyContents.IRDownload.Form.placeholder.category")}
        </span>
        {isOpen ? (
          <div className="arrow up"></div>
        ) : (
          <div className="arrow down"></div>
        )}
      </div>
      {isOpen && (
        <ul className="options_list">
          {options.map((option) => (
            <li
              key={option}
              className={`option_item ${
                selectedOption === option ? "active" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
