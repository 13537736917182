import { useMemo } from "react";
import { categories } from "../../util/data/categoris";

function ContentBridge({ category }) {
  const categoryData = useMemo(
    () => categories.find((cat) => cat.category === category),
    [category]
  );
  const ContentComponent = categoryData ? categoryData.Content : null;

  return ContentComponent ? <ContentComponent /> : null;
}

export default ContentBridge;
