import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as MoreArrowSvg } from "../../assets/images/서비스_런모어_화살표.svg";
import { ReactComponent as SolutionSvg } from "../../assets/images/서비스_솔루션.svg";
import { ReactComponent as ServiceSvg } from "../../assets/images/서비스_서비스.svg";

function ServiceContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const boxes = [
    {
      title: "Solution",
      subTitle: t("serviceTranslation.content.solutionDetailTitle"),
      frameComponent: SolutionSvg,
      boxType: "solution",
      navigateTo: "./solutionDetail",
    },
    {
      title: "Service",
      subTitle: t("serviceTranslation.content.serviceDetailTitle"),
      frameComponent: ServiceSvg,
      boxType: "service",
      navigateTo: "./serviceDetail",
    },
  ];

  const [hoverStates, setHoverStates] = useState(boxes.map(() => false));

  const handleMouseEnter = (index) => {
    setHoverStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <div className="service_content_wrapper">
      <div className="service_content_inner">
        <div className="service_container">
          <p className="solution_title">Solution & Services</p>
          <p className="solution_text">
            {t("serviceTranslation.content.contentExplan")}
          </p>
          <div className="grid_container">
            {boxes.map((box, index) => {
              const isHover = hoverStates[index];
              const FrameComponent = box.frameComponent;

              return (
                <div
                  key={index}
                  className={`box_container ${box.boxType}_box_container ${
                    isHover ? "hovered" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  onClick={() => navigate(box.navigateTo)}
                >
                  <div className="box_inner_context">
                    <div className="service_name">{box.title}</div>
                    <div className="sub_name">{box.subTitle}</div>
                    <p className="learn_more_link">
                      Learn More
                      <MoreArrowSvg
                        className="more_arrow"
                        style={{
                          width: "0.437rem",
                          fill: isHover ? "#181918" : "#ffffff",
                          marginLeft: "0.2rem",
                        }}
                      />
                    </p>
                  </div>
                  <div className="frame_box">
                    <div className="frame_content">
                      <FrameComponent
                        className={`frame_component ${
                          box.boxType
                        }_frame_component ${isHover ? "hovered" : ""}`}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceContent;
