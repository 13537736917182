function SkeletonNewsContent() {
  return (
    <li className="news_item skeleton">
      <div className="news_link">
        <div className="news_img skeleton-img"></div>
        <div className="content_box">
          <div className="news_title skeleton-text"></div>
          <div className="news_at skeleton-text"></div>
        </div>
      </div>
    </li>
  );
}

export default SkeletonNewsContent;
