import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect";
import { irDownloadAlert } from "../../hooks/irDownloadAlert";

function IRDownload() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
    emailId: "",
    emailDomain: "",
    customDomain: "",
    name: "",
    industry: "",
    phone: "",
    agree1: false,
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);
      updatedData.customDomain =
        updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : "";
      return updatedData;
    });
  };

  const handleEmailDomainChange = (value) => {
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        emailDomain: value,
      };

      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);
      updatedData.customDomain =
        updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : "";
      return updatedData;
    });
  };

  const handleAgreeClick = (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      agree1: !prev.agree1,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name) {
      alert(t("CompanyContents.IRDownload.Form.Alert.name"));
      return;
    } else if (!formData.industry) {
      alert(t("CompanyContents.IRDownload.Form.Alert.industry"));
      return;
    } else if (!formData.emailId) {
      alert(t("CompanyContents.IRDownload.Form.Alert.emailId"));
      return;
    } else if (!formData.phone) {
      alert(t("CompanyContents.IRDownload.Form.Alert.phone"));
      return;
    } else if (!formData.agree1) {
      alert(t("CompanyContents.IRDownload.Form.Alert.agree1"));
      return;
    }
    if (
      formData.emailDomain === "직접입력" ||
      formData.emailDomain === "Enter Email Domain"
    ) {
      if (!formData.customDomain) {
        alert(t("CompanyContents.IRDownload.Form.Alert.customDomain"));
        return;
      }
    } else if (!formData.emailDomain) {
      alert(t("CompanyContents.IRDownload.Form.Alert.emailDomain"));
      return;
    }
    var today = new Date();

    var year = today.getFullYear();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    var dateString = year + "-" + month + "-" + day;
    var hours = ("0" + today.getHours()).slice(-2);
    var minutes = ("0" + today.getMinutes()).slice(-2);
    var seconds = ("0" + today.getSeconds()).slice(-2);
    var timeString = hours + ":" + minutes + ":" + seconds;

    const formDataToSubmit = {
      다운로드시간: dateString + " " + timeString,
      이메일: formData.email,
      이름: formData.name,
      산업군: formData.industry,
      휴대폰번호: formData.phone,
      개인정보수집동의: formData.agree1,
    };
    const dataToSubmit = new FormData();
    for (const key in formDataToSubmit) {
      dataToSubmit.append(key, formDataToSubmit[key]);
    }
    try {
      fetch(process.env.REACT_APP_COMPANY_APPSCRIPTS_URL, {
        method: "POST",
        body: dataToSubmit,
      });
      irDownloadAlert(
        t("CompanyContents.IRDownload.Form.Alert.SuccessResultMessage")
      );
    } catch {
      irDownloadAlert(
        t("CompanyContents.IRDownload.Form.Alert.FailResultMessage")
      );
    }
    // try {
    //   let fileUrl = `${process.env.PUBLIC_URL}/assets/Return Plus IR Document.docx`;
    //   const link = document.createElement("a");
    //   link.href = fileUrl;
    //   link.download = "(주)리턴플러스 IR 자료.docx";
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // } catch (error) {
    //   alert(t("EmailForm.browserError"));
    // }
  };

  return (
    <div className="irdownload_form_wrapper">
      <div className="irdownload_inner">
        <form className="irdownload_form_box" onSubmit={handleSubmit}>
          <div className="irdownload_title_box">
            <span className="irdownload_title" id="irdown">
              IR Download
            </span>
            <span className="irdownload_sub_title">
              {t("CompanyContents.IRDownload.SubTitle")}
            </span>
          </div>
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.name")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_input_box irdownload_with_border">
              <input
                type="text"
                name="name"
                onChange={handleChange}
                required
                placeholder={t(
                  "CompanyContents.IRDownload.Form.placeholder.input"
                )}
                className="irdownload_input_field"
              />
            </div>
          </div>
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.industry")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_input_box">
              <CustomSelect
                options={t(
                  "CompanyContents.IRDownload.Form.industrySelectBox",
                  {
                    returnObjects: true,
                  }
                )}
                onChange={(value) =>
                  setFormData({ ...formData, industry: value })
                }
                required
              />
            </div>
          </div>
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.email")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_email_input_box">
              <input
                type="text"
                name="emailId"
                placeholder={t(
                  "CompanyContents.IRDownload.Form.placeholder.input"
                )}
                onChange={handleChange}
                required
                className="irdownload_email_input"
              />
              <div className="irdownload_email_domain_sign_box">
                <span className="irdownload_email_span">@</span>
              </div>
              <div className="irdownload_email_input_inner">
                <input
                  name="customDomain"
                  placeholder={
                    formData.emailDomain === "직접입력" ||
                    formData.emailDomain === "Enter Email Domain"
                      ? t("CompanyContents.IRDownload.Form.placeholder.input")
                      : t(
                          "CompanyContents.IRDownload.Form.placeholder.category"
                        )
                  }
                  value={
                    formData.emailDomain === "직접입력" ||
                    formData.emailDomain === "Enter Email Domain"
                      ? formData.customDomain
                      : formData.emailDomain
                  }
                  disabled={
                    formData.emailDomain !== "직접입력" &&
                    formData.emailDomain !== "Enter Email Domain"
                  }
                  onChange={handleChange}
                  required
                  className="irdownload_email_input_current"
                />
                <div className="irdownload_custom_select_box">
                  <CustomSelect
                    options={t(
                      "CompanyContents.IRDownload.Form.domainSelectBox",
                      {
                        returnObjects: true,
                      }
                    )}
                    onChange={handleEmailDomainChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="irdownload_form_inner">
            <div className="irdownload_label_box irdownload_align_center">
              <label className="irdownload_label">
                {t("CompanyContents.IRDownload.Form.phone")}
                <span className="irdownload_label_span"> *</span>
              </label>
            </div>
            <div className="irdownload_input_box irdownload_with_border">
              <input
                type="text"
                placeholder={t(
                  "CompanyContents.IRDownload.Form.placeholder.phone"
                )}
                name="phone"
                onChange={handleChange}
                maxLength="12"
                minLength="9"
                required
                className="irdownload_input_field"
              />
            </div>
          </div>
          <div className="irdownload_checkbox_wrapper">
            <div className="irdownload_checkbox_box">
              <input
                type="checkbox"
                name="agree1"
                onChange={handleChange}
                checked={formData.agree1}
                className="irdownload_checkbox_input"
                id="irdownload_agree1"
              />
              <div
                className={`irdownload_custom_checkbox ${
                  formData.agree1 ? "irdownload_checked" : ""
                }`}
                onClick={handleAgreeClick}
              ></div>
              <label
                htmlFor="irdownload_agree1"
                className="irdownload_checkbox_label"
                onClick={handleAgreeClick}
              >
                {t("CompanyContents.IRDownload.Form.privacyPolicy.Title")}
              </label>
            </div>
            <p className="irdownload_privacy_policy_content irdownload_subtitle">
              {t("CompanyContents.IRDownload.Form.privacyPolicy.SubTitle")}
            </p>
            <p className="irdownload_privacy_policy_content irdownload_content_title">
              {t("CompanyContents.IRDownload.Form.privacyPolicy.ContentTitle")}
            </p>
            <div className="irdownload_privacy_policy_grid">
              <div className="irdownload_privacy_policy_grid_item irdownload_collect_item">
                {t(
                  "CompanyContents.IRDownload.Form.privacyPolicy.Grid.CollectItem"
                )}
              </div>
              <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose">
                {t(
                  "CompanyContents.IRDownload.Form.privacyPolicy.Grid.CollectPurpose"
                )}
              </div>
              <div className="irdownload_privacy_policy_grid_item irdownload_collect_term">
                {t(
                  "CompanyContents.IRDownload.Form.privacyPolicy.Grid.CollectTerm"
                )}
              </div>

              <div className="irdownload_privacy_policy_grid_item irdownload_collect_item_content">
                {t(
                  "CompanyContents.IRDownload.Form.privacyPolicy.Grid.CollectItemContent"
                )}
              </div>
              <div className="irdownload_privacy_policy_grid_item irdownload_collect_purpose_content">
                {t(
                  "CompanyContents.IRDownload.Form.privacyPolicy.Grid.CollectPurposeContent"
                )}
              </div>
              <div className="irdownload_privacy_policy_grid_item irdownload_collect_term_content">
                {t(
                  "CompanyContents.IRDownload.Form.privacyPolicy.Grid.CollectTermContent"
                )}
              </div>
            </div>
            <p className="irdownload_privacy_policy_content irdownload_warning">
              {t("CompanyContents.IRDownload.Form.privacyPolicy.Warning")}
            </p>
          </div>
          <div className="irdownload_button_box">
            <button
              type="button"
              className="irdownload_submit_button"
              onClick={handleSubmit}
            >
              {t("CompanyContents.IRDownload.Form.Button")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default IRDownload;
