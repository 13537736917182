function ContactBanner() {
  return (
    <div className="contact_banner_wrapper" style={{ marginTop: "6rem" }}>
      <img
        src="https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/1f7e226e-e836-490a-a224-6d739899a700/banner"
        alt="Contact Banner"
        className="contact_banner_img"
        loading="lazy"
        decoding="async"
      />
      <div className="contact_banner_content_box">
        <p className="nav_content">Home · Contact Us</p>
        <p className="banner_title">Contact Us</p>
      </div>
    </div>
  );
}

export default ContactBanner;
