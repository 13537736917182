function SkeletonNewsBanner() {
  return (
    <div className="news_banner_wrapper" style={{ marginTop: "6rem" }}>
      <div className="news_banner_skeleton_slide">
        <div className="news_banner_skeleton_image"></div>
        <div className="news_banner_skeleton_text title"></div>
        <div className="news_banner_skeleton_text content"></div>
        <div className="news_banner_skeleton_button"></div>
      </div>
    </div>
  );
}

export default SkeletonNewsBanner;
