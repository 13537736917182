import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import hanjogak from "../../assets/images/서비스_서비스_한조각.avif";
import wallet from "../../assets/images/서비스_서비스_월렛.avif";
import trade from "../../assets/images/서비스_서비스_트레이딩.avif";
import { useNotReadyAlert } from "../../hooks/useNotReadyAlert";

function ServiceDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showAlert, isPending, startTransition } = useNotReadyAlert();

  const naviCalculator = () => {
    navigate("/");
  };

  const serviceImageUrls = [hanjogak, wallet, trade];

  return (
    <div className="service_detail_wrapper">
      <div className="service_container">
        <div className="service_detail_title">Service</div>
        <div className="service_detail_text" id="product">
          {t("serviceTranslation.serviceDetail.servicePageSubTitle")}
        </div>
        {serviceImageUrls.map((url, index) => (
          <div id={index} className="service_list_container" key={index}>
            <div className="service_content_box">
              <div
                className="service_image"
                style={{ backgroundImage: `url(${url})` }}
              ></div>
              <div className="service_item">
                <div className="item_title_box">
                  <div className="service_item_title">
                    {t(
                      `serviceTranslation.serviceDetail.service${
                        index + 1
                      }.serviceTitle`
                    )}
                  </div>
                  {t(
                    `serviceTranslation.serviceDetail.service${
                      index + 1
                    }.serviceTitle`
                  ) === "한조각" && (
                    <div className="sub_title">
                      {t(
                        `serviceTranslation.serviceDetail.service${
                          index + 1
                        }.serviceSubTitle`
                      )}
                    </div>
                  )}
                </div>
                <div className="text_box">
                  {t(
                    `serviceTranslation.serviceDetail.service${
                      index + 1
                    }.serviceContent`
                  )}
                </div>
                {(t(
                  `serviceTranslation.serviceDetail.service${
                    index + 1
                  }.serviceTitle`
                ) === "한조각" ||
                  t(
                    `serviceTranslation.serviceDetail.service${
                      index + 1
                    }.serviceTitle`
                  ) === "Hanjokak") && (
                  <button
                    className="navigate_button"
                    onClick={() => startTransition(showAlert)}
                    disabled={isPending}
                  >
                    Real Estate Tools
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceDetail;
