export const contact_i18n = {
  ko: {
    ContactContents: {
      RoadTitle: "오시는 길",
      Grid: {
        HeadOfficeAddress: "본사",
        R8DAddress: "R&D연구센터",
        addressContent1: "서울특별시 금천구 가산디지털2로 144, 414호",
        addressContent2: "서울특별시 강남구 선릉로86길 40 4층 ",
        contactPhone: "전화문의",
        contactPhoneContent: "010-4929-0070",
        contactEmail: "메일문의",
        contactEmailContent: "contact@returnplus.kr",
        contactTraffic: "교통",
        contactTrafficContentFirst: "2호선·수인분당선",
        contactTrafficContentSecond: "선릉역 1번 출구에서 364m",
      },
      ContactUs: {
        SubTitle: "문의사항을 남겨주시면 신속하게 답변 드리겠습니다.",
        Form: {
          contactType: "문의 분류",
          name: "이름",
          contactTypeSelectBox: ["제품문의", "제휴", "기타"],
          email: "이메일 주소",
          domainSelectBox: ["naver.com", "google.com", "daum.net", "직접입력"],
          phone: "휴대폰 번호",
          placeholder: {
            input: "입력해 주세요",
            category: "카테고리를 선택해주세요",
            phone: "01012345678",
            contactTitle: "제목을 입력해 주세요.(20자 이내)",
            contactContents: "내용을 입력해 주세요",
          },
          contactTitle: "문의 제목",
          contactContents: "문의 내용",
          privacyPolicy: {
            Title: "(필수) 개인정보 수집·이용동의에 대한 안내",
            SubTitle: "개인정보 수집·이용에 대한 안내",
            ContentTitle:
              "(주)리턴플러스는 이용자 문의를 처리하기 위해 다음과 같이 개인정보를 수집 및 이용하며, 이용자의 개인정보를 안전하게 취급하는데 최선을 다하고 있습니다.",
            Grid: {
              CollectItem: "수집항목",
              CollectItemContent: "이메일 주소, 휴대폰 번호",
              CollectPurpose: "수집목적",
              CollectPurposeContent: "문의·요청·불편사항 확인 및 처리결과 회신",
              CollectTerm: "보유기간",
              CollectTermContent: "3년간 보관 후 지체없이 파기",
            },
            Warning:
              "위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 문의처리 및 결과 회신이 제한됩니다. 요구하지 않은 개인정보는 입력하지 않도록 주의해주세요.",
          },
          Button: "문의접수",
          Alert: {
            inquiryType: "문의 분류를 선택해 주세요.",
            name: "이름을 입력해주세요.",
            emailId: "이메일 아이디를 입력해주세요.",
            phone: "휴대폰번호를 입력해주세요.",
            inquiryTitle: "문의 제목을 입력해주세요",
            Message: "문의 내용을 입력해주세요.",
            agree1: "개인정보 수집 및 이용에 동의해주세요.",
            customDomain: "도메인을 입력해 주세요.",
            emailDomain: "도메인을 선택해주세요.",
            SuccessResultMessage:
              "문의가 정상적으로 제출되었습니다. 빠른시일내에 연락드리겠습니다. 감사합니다.",
            FailResultMessage:
              "지원되지않는 브라우저입니다. Chrome으로 접속해주세요.",
          },
        },
      },
    },
  },
  en: {
    ContactContents: {
      RoadTitle: "Directions",
      Grid: {
        HeadOfficeAddress: "Head Office",
        R8DAddress: "R&D Center",
        addressContent1:
          "144, Gasan Digital 2-ro, Geumcheon-gu, Seoul, 4th Floor, 414",
        addressContent2:
          "40, Seolleung-ro 86-gil, Gangnam-gu, Seoul, 4th Floor",
        contactPhone: "Phone Inquiry",
        contactPhoneContent: "010-4929-0070",
        contactEmail: "Email Inquiry",
        contactEmailContent: "contact@returnplus.kr",
        contactTraffic: "Traffic",
        contactTrafficContentFirst: "Line 2 / Suin-Bundang Line",
        contactTrafficContentSecond: "364m from Exit 1 of Seolleung Station",
      },
      ContactUs: {
        SubTitle: "Please leave your inquiries, and we will respond promptly.",
        Form: {
          contactType: "Inquiry Type",
          name: "Name",
          contactTypeSelectBox: ["Product Inquiry", "Partnership", "Others"],
          email: "Email Address",
          domainSelectBox: [
            "naver.com",
            "google.com",
            "daum.net",
            "Enter Email Domain",
          ],
          phone: "Mobile Number",
          placeholder: {
            input: "Please enter",
            category: "Please select a category",
            phone: "01012345678",
            contactTitle: "Please enter a title (up to 20 characters)",
            contactContents: "Please enter your message",
          },
          contactTitle: "Inquiry Title",
          contactContents: "Inquiry Content",
          privacyPolicy: {
            Title:
              "(Required) Notice on Consent to Collect and Use Personal Information",
            SubTitle: "Notice on Collection and Use of Personal Information",
            ContentTitle:
              "Return Plus Co., Ltd. collects and uses personal information as follows to process user inquiries, and strives to handle users' personal information safely.",
            Grid: {
              CollectItem: "Collected Items",
              CollectItemContent: "Email address, mobile number",
              CollectPurpose: "Purpose of Collection",
              CollectPurposeContent:
                "To confirm and respond to inquiries, requests, and complaints",
              CollectTerm: "Retention Period",
              CollectTermContent:
                "Retained for 3 years and then promptly destroyed",
            },
            Warning:
              "You have the right to refuse consent, and if you refuse, your inquiry processing and response may be limited. Please be careful not to enter unnecessary personal information.",
          },
          Button: "Submit Inquiry",
          Alert: {
            inquiryType: "Please select an inquiry category.",
            name: "Please enter your name.",
            emailId: "Please enter your email ID.",
            phone: "Please enter your mobile number.",
            inquiryTitle: "Please enter the inquiry title.",
            Message: "Please enter the inquiry message.",
            agree1:
              "Please agree to the collection and use of personal information.",
            customDomain: "Please enter the domain.",
            emailDomain: "Please select a domain.",
            SuccessResultMessage:
              "Your inquiry has been successfully submitted. We will contact you as soon as possible. Thank you.",
            FailResultMessage:
              "This browser is not supported. Please use Chrome.",
          },
        },
      },
    },
  },
};
