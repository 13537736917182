import BannerBridge from "../../components/Banners/BannerBridge";
import ContentBridge from "../../components/Contents/ContentBridge";

function Contact() {
  return (
    <div className="contact_container">
      <BannerBridge category="contact" />
      <ContentBridge category="contact" />
    </div>
  );
}
export default Contact;
