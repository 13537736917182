// src/components/NewsBanner.jsx
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowSvg } from "../../assets/images/푸터_패밀리사이트_화살표.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInterval from "../../hooks/useInterval";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../store/newsSlice";
import SkeletonNewsBanner from "../SkeletonUI/SkeletonNewsBanner";

function NewsBanner() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [intervalTrigger, setIntervalTrigger] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const slideContainerRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const slideImages = [
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/91da8915-b57e-4902-a6d0-bc6724a80700/news",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/7fa068e9-ab3a-45f6-c247-d8857fbb4b00/news",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/5ff90474-5f7d-416c-d617-34a4e92ae700/news",
  ];
  const { newsList, status } = useSelector((state) => state.news);

  const totalSlides = Math.min(3, newsList.length);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const handleNextSlide = () => {
    goToSlide(currentSlide + 1);
    setIntervalTrigger((prev) => prev + 1);
  };

  const handlePrevSlide = () => {
    goToSlide(currentSlide - 1);
    setIntervalTrigger((prev) => prev + 1);
  };

  useInterval(() => handleNextSlide(), 5000, [intervalTrigger]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (currentSlide > totalSlides + 1 || currentSlide < 0) {
      setCurrentSlide(1);
    }
  }, [currentSlide, totalSlides]);

  useEffect(() => {
    const slideContainer = slideContainerRef.current;
    if (!slideContainer) return;

    slideContainer.style.transition = "transform 0.5s ease-in-out";
    slideContainer.style.transform = `translateX(-${currentSlide * 100}%)`;

    const handleTransitionEnd = () => {
      if (currentSlide === totalSlides + 1) {
        requestAnimationFrame(() => {
          slideContainer.style.transition = "none";
          setCurrentSlide(1);
          slideContainer.style.transform = `translateX(-100%)`;
          requestAnimationFrame(() => {
            slideContainer.style.transition = "transform 0.5s ease-in-out";
          });
        });
      } else if (currentSlide === 0) {
        requestAnimationFrame(() => {
          slideContainer.style.transition = "none";
          setCurrentSlide(totalSlides);
          slideContainer.style.transform = `translateX(-${totalSlides * 100}%)`;
          requestAnimationFrame(() => {
            slideContainer.style.transition = "transform 0.5s ease-in-out";
          });
        });
      }
    };

    slideContainer.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      slideContainer.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentSlide, totalSlides]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchNews());
    }
  }, [status, dispatch]);

  const removeBrTags = (text) => {
    return text.replace(/<br\s*\/?>/gi, " ");
  };

  // 조건문 순서 변경
  if (status === "loading") {
    return <SkeletonNewsBanner />;
  }

  if (totalSlides === 0) {
    return null;
  }

  const slides = [newsList[totalSlides - 1], ...newsList, newsList[0]];

  return (
    <div className="news_banner_wrapper" style={{ marginTop: "6rem" }}>
      <div
        className="slides_container"
        ref={slideContainerRef}
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        <div className="slide_item clone_last">
          <img
            src={slideImages[totalSlides - 1]}
            alt="News Slide"
            className="slide_img"
            loading="lazy"
            decoding="async"
          />
          <span className="slide_category">Home · News</span>
          <span className="slide_title">{slides[0].title}</span>
          <span className="slide_content">
            {removeBrTags(slides[0].content)}
          </span>
          <Link to={`/news/${slides[0]._id}`} className="go_news_btn">
            {t("newsTranslation.goToArticle")}
          </Link>
        </div>
        {newsList.map((data, index) => (
          <div className="slide_item" key={data._id}>
            <img
              src={slideImages[index]}
              alt="News Slide"
              className="slide_img"
              loading="lazy"
              decoding="async"
            />
            <span className="slide_category">Home · News</span>
            <span className="slide_title">{data.title}</span>
            <span className="slide_content">
              {removeBrTags(data.content)}
            </span>
            <Link to={`/news/${data._id}`} className="go_news_btn">
              {t("newsTranslation.goToArticle")}
            </Link>
          </div>
        ))}
        <div className="slide_item clone_first">
          <img
            src={slideImages[0]}
            alt="News Slide"
            className="slide_img"
            loading="lazy"
            decoding="async"
          />
          <span className="slide_category">Home · News</span>
          <span className="slide_title">
            {slides[slides.length - 1].title}
          </span>
          <span className="slide_content">
            {removeBrTags(slides[slides.length - 1].content)}
          </span>
          <Link
            to={`/news/${slides[slides.length - 1]._id}`}
            className="go_news_btn"
          >
            {t("newsTranslation.goToArticle")}
          </Link>
        </div>
      </div>

      <button
        className="slide_btn prev_slide_btn"
        onClick={handlePrevSlide}
        aria-label="이전 슬라이드로 이동"
      >
        <ArrowSvg className="prev_arrow" />
      </button>
      <button
        className="slide_btn next_slide_btn"
        onClick={handleNextSlide}
        aria-label="다음 슬라이드로 이동"
      >
        <ArrowSvg className="next_arrow" />
      </button>

      <ul className="pagination_dots">
        {newsList.map((_, index) => {
          const isActive =
            !isFirstRender &&
            (currentSlide === index + 1 ||
              (currentSlide === 0 && index === totalSlides - 1) ||
              (currentSlide === totalSlides + 1 && index === 0));

          return (
            <li
              key={index}
              className={`pagination_dot ${isActive ? "active" : ""}`}
              onClick={() => {
                goToSlide(index + 1);
                setIntervalTrigger((prev) => prev + 1);
              }}
              aria-label={`슬라이드 ${index + 1}`}
            ></li>
          );
        })}
      </ul>
    </div>
  );
}

export default NewsBanner;