import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as InstaSvg } from "../../assets/images/푸터_인스타그램.svg";
import { ReactComponent as BlogSvg } from "../../assets/images/푸터_네이버블로그.svg";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/푸터_로고.avif";
import { useNotReadyAlert } from "../../hooks/useNotReadyAlert";

function Footer() {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { showAlert, isPending, startTransition } = useNotReadyAlert();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <footer className="footer_wrapper">
      <div className="footer_inner">
        <div className="info_box">
          <span className="business">{t("footerTranslation.companyName")}</span>
          <div className="logo">
            <img
              src={logo}
              alt="로고"
              className="logo_img"
              loading="lazy"
              decoding="async"
            />
          </div>
          <div className="info">
            <p>{t("footerTranslation.representative")}</p>
            <p>{t("footerTranslation.headOffice1")}</p>
            <p>{t("footerTranslation.headOffice2")}</p>
            <p>{t("footerTranslation.otherInformation")}</p>
          </div>
          <div className="ir">
            <Link to="/company/#irdown" className="ir_link">
              {t("footerTranslation.inquiryBtn")}
            </Link>
            <span className="separator">|</span>
            <a
              href="https://www.saramin.co.kr/zf_user/search?searchType=search&company_cd=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C9%2C10&keydownAccess=&searchword=%EB%A6%AC%ED%84%B4%ED%94%8C%EB%9F%AC%EC%8A%A4&panel_type=&search_optional_item=y&search_done=y&panel_count=y&preview=y"
              target="_blank"
              rel="noopener noreferrer"
              className="ir_link"
            >
              {t("footerTranslation.employmentBtn")}
            </a>
          </div>
          <div className="copyright">{t("footerTranslation.copyright")}</div>
          <div className="sns_box">
            <a
              // href="https://www.instagram.com"
              // target="_blank"
              // rel="noopener noreferrer"
              className="sns_link"
              onClick={() => startTransition(showAlert)}
              disabled={isPending}
            >
              <InstaSvg className="sns_icon insta_icon" />
            </a>
            <a
              // href="https://blog.naver.com"
              // target="_blank"
              // rel="noopener noreferrer"
              className="sns_link"
              onClick={() => startTransition(showAlert)}
              disabled={isPending}
            >
              <BlogSvg className="sns_icon blog_icon" />
            </a>
          </div>
        </div>
        <div className="map_box">
          <div className="title">{t("footerTranslation.siteMap")}</div>
          <div className="map">
            <ul className="sitemap_list">
              <li className="category">
                <Link to="/">About</Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#solution">Solution</Link>
              </li>
              <li>
                <Link to="/#platform">Platform</Link>
              </li>
              <li>
                <Link to="/#app">APP</Link>
              </li>
              <li>
                <Link to="/#news">News</Link>
              </li>
              <li>
                <Link to="/#contact">Contact Us</Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link to="/company">Company</Link>
              </li>
              <li>
                <Link to="/company/#vision">Vision</Link>
              </li>
              <li>
                <Link to="/company/#history">History</Link>
              </li>
              <li>
                <Link to="/company/#talent">Type of talent</Link>
              </li>
              <li>
                <Link to="/company/#irdown">IR Download</Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link to="/service">Service</Link>
              </li>
              <li>
                <Link to="/service/solutionDetail">Solution</Link>
              </li>
              <li>
                <Link to="/service/serviceDetail">Product</Link>
              </li>
              <li>
                <Link to="/service/#retool">RE Tools</Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link to="/news">News</Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/contact/#way">Way to Come</Link>
              </li>
              <li>
                <Link to="/contact/#contactform">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="link_box">
            <div className="custom_dropdown_container" ref={dropdownRef}>
              <button
                className="dropdown_button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {t("footerTranslation.familySite")}
                <span
                  className={`arrow ${isDropdownOpen ? "up" : "down"}`}
                ></span>
              </button>
              {isDropdownOpen && (
                <div className="dropdown_menu">
                  <a
                    // href="https://hanjogak.example.com"
                    // target="_blank"
                    // rel="noopener noreferrer"
                    onClick={() => startTransition(showAlert)}
                    disabled={isPending}
                    className="dropdown_option"
                  >
                    Han Jogak
                  </a>
                  <a
                    href="http://daeyeonpnc.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown_option"
                  >
                    DeyeonP&C
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
