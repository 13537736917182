import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom"; // Link 대신 NavLink 사용
import { ReactComponent as TransSvg } from "../../assets/images/헤더_지구본_화살표.svg";
import { ReactComponent as CheckSvg } from "../../assets/images/헤더_언어변경박스_체크.svg";
import logo from "../../assets/images/헤더_로고.avif";
import { useTranslation } from "react-i18next";

function Header() {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const translationRef = useRef(null);

  const { t, i18n } = useTranslation();

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        translationRef.current &&
        !translationRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, translationRef]);

  const handleLanguageSelect = (lang) => {
    console.log(`Selected language: ${lang}`);
    i18n.changeLanguage(lang);
    setIsModalOpen(false);
  };

  return (
    <header className={`header_wrapper ${showHeader ? "visible" : "hidden"}`}>
      <div className="header_inner">
        <NavLink to="/" className="logo_box">
          <img
            src={logo}
            alt="로고"
            className="logo_img"
            loading="lazy"
            decoding="async"
          />
        </NavLink>
        <div className="nav_box">
          <NavLink to="/company" className="nav_item">
            Company
          </NavLink>
          <NavLink to="/service" className="nav_item">
            Service
          </NavLink>
          <NavLink to="/news" className="nav_item">
            News
          </NavLink>
          <NavLink to="/contact" className="nav_item">
            Contact Us
          </NavLink>
        </div>
        <div className="tool_box">
          <NavLink to="/contact/#contactform" className="inquiry">
            {t("mainTranslation.contactUsBtn")}
          </NavLink>
          <span className="separator">|</span>
          <button
            className="translation_button"
            onClick={() => setIsModalOpen(!isModalOpen)}
            ref={translationRef}
          >
            <TransSvg className="trans_svg" />
            {isModalOpen && (
              <>
                <div className="modal_overlay"></div>
                <div className="modal_container" ref={modalRef}>
                  <button
                    className="modal_option"
                    onClick={() => handleLanguageSelect("ko")}
                  >
                    <div className="icon_wrapper">
                      {i18n.language === "ko" && <CheckSvg width={13} />}
                    </div>
                    <span>KO</span>
                  </button>
                  <button
                    className="modal_option"
                    onClick={() => handleLanguageSelect("en")}
                  >
                    <div className="icon_wrapper">
                      {i18n.language === "en" && <CheckSvg width={13} />}
                    </div>
                    <span>EN</span>
                  </button>
                </div>
              </>
            )}
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
