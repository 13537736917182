import { useEffect, useRef } from "react";

const NaverMapDefault = () => {
  const mapElement = useRef(null);
  const { naver } = window;

  useEffect(() => {
    if (!mapElement.current || !naver) return;
    const location = new naver.maps.LatLng(
      37.50388163380698,
      127.05291454387448
    );
    const mapOptions = {
      center: location,
      zoom: 16,
      zoomControl: true,
      scaleControl: true,
      zoomControlOptions: { position: 8 },
    };

    const map = new naver.maps.Map(mapElement.current, mapOptions);
    const marker = new naver.maps.Marker({
      position: location,
      map,
    });
    const infoWindow = new naver.maps.InfoWindow({
      content: [
        '<div style="padding: 3px 5px 0px 5px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px 0px; border: 1px solid #000000;">',
        `   <div style="color: #000000; margin-bottom: 5px;">리턴플러스</div>`,
        "</div>",
      ].join(""),
      anchorSize: {
        width: 15,
        height: 20,
      },
      borderColor: "#000000",
    });
    infoWindow.open(map, marker);
    naver.maps.Event.addListener(marker, "click", () => {
      if (infoWindow.getMap()) {
        infoWindow.close();
      } else if (map !== null) {
        infoWindow.open(map, marker);
      }
    });
  }, [naver]);

  return (
    <>
      <div ref={mapElement} style={{ width: "100%", height: "100%" }} />
    </>
  );
};

export default NaverMapDefault;
