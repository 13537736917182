import BannerBridge from "../../components/Banners/BannerBridge";
import ContentBridge from "../../components/Contents/ContentBridge";

function Company() {
  return (
    <div className="company_container">
      <BannerBridge category="company" />
      <ContentBridge category="company" />
    </div>
  );
}
export default Company;
