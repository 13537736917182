import BannerBridge from "../../components/Banners/BannerBridge";
import ContentBridge from "../../components/Contents/ContentBridge";

function Main() {
  return (
    <div className="main_container">
      <BannerBridge category="main" />
      <ContentBridge category="main" />
    </div>
  );
}
export default Main;
