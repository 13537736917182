export const companyHistoryData = {
  2024: [
    {
      month: "05",
      content: "CompanyContents.History.2024.May",
      description: "Title",
    },
    {
      month: "07",
      content: "CompanyContents.History.2024.July",
      description: "subTitle",
    },
    {
      month: "12",
      content: "CompanyContents.History.2024.December",
      description: "subTitle",
    },
  ],
  2025: [
    {
      month: "03",
      content: "CompanyContents.History.2025.March",
      description: "Title",
    },
    {
      month: "04",
      content: "CompanyContents.History.2025.April",
      description: "subTitle",
    },
    {
      month: "09",
      content: "CompanyContents.History.2025.September",
      description: "subTitle",
    },
    {
      month: "12",
      content: "CompanyContents.History.2025.December",
      description: "subTitle",
    },
  ],
  2026: [
    {
      month: "03",
      content: "CompanyContents.History.2026.March",
      description: "Title",
    },
    {
      month: "06",
      content: "CompanyContents.History.2026.June",
      description: "subTitle",
    },
    {
      month: "11",
      content: "CompanyContents.History.2026.November",
      description: "subTitle",
    },
  ],
};
