import { useTranslation } from "react-i18next";
import block from "../../assets/images/서비스_솔루션_블록체인.avif";
import auto from "../../assets/images/서비스_솔루션_자동화.avif";
import ai from "../../assets/images/서비스_솔루션_AI.avif";

function SolutionDetail() {
  const { t } = useTranslation();

  const imageUrls = [block, auto, ai];

  return (
    <div className="solution_detail_wrapper">
      <div className="solution_container">
        <div className="solution_detail_title">Solution</div>
        <div className="solution_detail_text" id="s-solution">
          {t("serviceTranslation.solutionDetail.solutionPageSubTitle")}
        </div>
        {imageUrls.map((url, index) => (
          <div className="solution_list_container" key={index}>
            <div className="solution_content_box">
              <div
                className="solution_image"
                style={{ backgroundImage: `url(${url})` }}
              ></div>
              <div className="solution_item">
                <div className="item_title_box">
                  <div className="solution_item_title">
                    {t(
                      `serviceTranslation.solutionDetail.solution${
                        index + 1
                      }.solutionTitle`
                    )}
                  </div>
                  {["Distributed Ledger Technology", "Automatically"].includes(
                    t(
                      `serviceTranslation.solutionDetail.solution${
                        index + 1
                      }.solutionTitle`
                    )
                  ) && (
                    <div className="sub_title">
                      {t(
                        `serviceTranslation.solutionDetail.solution${
                          index + 1
                        }.solutionSubTitle`
                      )}
                    </div>
                  )}
                </div>
                <div className="text_box">
                  {t(
                    `serviceTranslation.solutionDetail.solution${
                      index + 1
                    }.solutionContent`
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SolutionDetail;
