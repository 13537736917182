import { useTranslation } from "react-i18next";

function CompanyBanner() {
  const { t } = useTranslation();

  return (
    <div className="company_banner_wrapper" style={{ marginTop: "6rem" }}>
      <img
        src="https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/18b7dd18-8be7-4383-7074-8d69f6035b00/banner"
        alt="Banner"
        className="banner_img"
        loading="lazy"
        decoding="async"
      />
      <div className="company_banner_content_box">
        <p className="nav_content">Home · Company</p>
        <p className="banner_title">Return Plus</p>
        <p className="banner_content">
          <span className="content_span delay_06">
            {t("CompanyBanner.FirstLine")}
          </span>
          <span className="content_span delay_08">
            {t("CompanyBanner.SecondLine")}
          </span>
        </p>
      </div>
    </div>
  );
}

export default CompanyBanner;
