import { useTranslation } from "react-i18next";

function ServiceBanner() {
  const { t } = useTranslation();

  return (
    <div className="service_banner_wrapper" style={{ marginTop: "6rem" }}>
      <img
        src="https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/d60dc493-cfc6-4a85-ee60-bac55d193000/banner"
        alt="Service Banner"
        className="service_banner_img"
        loading="lazy"
        decoding="async"
      />
      <div className="service_banner_text_container">
        <div className="banner_category_text">Home · Service</div>
        <div className="banner_main_text">Service</div>
        <div className="banner_content_text">
          {t("serviceTranslation.bannerExplain")}
        </div>
      </div>
    </div>
  );
}

export default ServiceBanner;
