import { MainBanner, CompanyBanner, ServiceBanner, NewsBanner, ContactBanner } from "../../components/Banners";
import { MainContent, CompanyContent, ServiceContent, NewsContent, ContactContent } from "../../components/Contents";

export const categories = [
    {
        category: "main",
        Banner: MainBanner,
        Content: MainContent,
    },
    {
        category: "company",
        Banner: CompanyBanner,
        Content: CompanyContent,
    },
    {
        category: "service",
        Banner: ServiceBanner,
        Content: ServiceContent,
    },
    {
        category: "news",
        Banner: NewsBanner,
        Content: NewsContent,
    },
    {
        category: "contact",
        Banner: ContactBanner,
        Content: ContactContent,
    },
];
