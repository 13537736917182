import { useState, useEffect, useRef, useMemo } from "react";
import { ReactComponent as StoSvg } from "../../assets/images/메인_솔루션_STO.svg";
import { ReactComponent as TradeSvg } from "../../assets/images/메인_솔루션_트레이딩.svg";
import { ReactComponent as WalletSvg } from "../../assets/images/메인_솔루션_월렛.svg";
import { ReactComponent as GoogleSvg } from "../../assets/images/메인_앱_구글.svg";
import { ReactComponent as AppleSvg } from "../../assets/images/메인_앱_애플.svg";
import { ReactComponent as ElecBulbSvg } from "../../assets/images/메인_뉴스_전구 아이콘.svg";
import { ReactComponent as GoToNewsSvg } from "../../assets/images/메인_뉴스_바로가기_화살표.svg";
import { ReactComponent as GoToSvg } from "../../assets/images/메인_뉴스_화살표.svg";
import news1 from "../../assets/images/메인_뉴스1.svg";
import news2 from "../../assets/images/메인_뉴스2.svg";
import news3 from "../../assets/images/메인_뉴스3.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHashScroll from "../../hooks/useHashScroll.js";
import useIntersection from "../../hooks/useIntersection.js";
import { useNotReadyAlert } from "../../hooks/useNotReadyAlert.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../store/newsSlice";
import { solutionData } from "../../util/data/Main/staticData.js";
import SkeletonNewsItem from "../SkeletonUI/SkeletonNewsItem.js";

function MainContent() {
  const { t } = useTranslation();
  const location = useHashScroll();
  const dispatch = useDispatch();
  const { newsList, status } = useSelector((state) => state.news);
  const [activeCategory, setActiveCategory] = useState("Hanjogak");
  const [i18nActiveCategory, setI18nActiveCategory] = useState("hanjogak");
  const [overlayTranslate, setOverlayTranslate] = useState(0);
  const [contactVisible, setContactVisible] = useState(false);
  const appBoxRef = useRef(null);
  const contactBoxRef = useRef(null);
  const overlayRef = useRef(null);
  const maxTranslate = 900;
  const { showAlert, isPending, startTransition } = useNotReadyAlert();

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (overlayRef.current) {
        const rect = overlayRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const visibleHeight =
          Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        const totalHeight = rect.height;

        let progress = visibleHeight / totalHeight;
        progress = Math.min(Math.max(progress, 0), 1);

        let newTranslate = progress * maxTranslate;

        if (rect.top < 0 && rect.bottom < windowHeight) {
          newTranslate = maxTranslate;
        }

        setOverlayTranslate(newTranslate);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const isAppVisible = useIntersection(appBoxRef, 0.1);
  const isContactVisible = useIntersection(contactBoxRef, 0.1);

  useEffect(() => {
    if (isContactVisible) {
      setContactVisible(true);
    }
  }, [isContactVisible]);

  const newsImgs = [news1, news2, news3];

  const data = useMemo(() => {
    return t("mainTranslation.platform", { returnObjects: true });
  }, [t]);

  return (
    <div className="main_content">
      <section className="content_box">
        <div className="content_inner">
          <h2 id="solution" className="title">
            Solution
          </h2>
          <p className="sub_title">Offering</p>
          <div className="item_box">
            <div className="item">
              <span className="item_title">Wallet</span>
              <div className="item_sub_title">
                {t("mainTranslation.solution.wallet")}
              </div>
              <WalletSvg
                style={{
                  display: "block",
                  margin: "0.8rem auto",
                  width: "13.54rem",
                  height: "10.62rem",
                }}
              />
              <Link className="item_link" to="/service/serviceDetail">
                <span className="item_link_inner">
                  <span>Start</span>
                  <GoToSvg
                    style={{
                      width: "0.44rem",
                      height: "0.44rem",
                    }}
                  />
                </span>
              </Link>
            </div>
            <div className="item">
              <span className="item_title">STO Platform</span>
              <div className="item_sub_title">
                {t("mainTranslation.solution.platform")}
              </div>
              <StoSvg
                style={{
                  display: "block",
                  margin: "0.8rem auto",
                  width: "10rem",
                  height: "12.5rem",
                }}
              />
              <Link className="item_link" to="/service/serviceDetail">
                <span className="item_link_inner">
                  <span>View</span>
                  <GoToSvg
                    style={{
                      width: "0.44rem",
                      height: "0.44rem",
                    }}
                  />
                </span>
              </Link>
            </div>
            <div className="item">
              <span className="item_title">Trading</span>
              <div className="item_sub_title">
                {t("mainTranslation.solution.trading")}
              </div>
              <TradeSvg
                style={{
                  display: "block",
                  margin: "0.8rem auto",
                  width: "10rem",
                  height: "12.5rem",
                }}
              />
              <Link className="item_link" to="/service/serviceDetail">
                <span className="item_link_inner">
                  <span>Obtain</span>
                  <GoToSvg
                    style={{
                      width: "0.44rem",
                      height: "0.44rem",
                    }}
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="content_box">
        <div className="content_inner">
          <h2 id="platform" className="title">
            Platform
          </h2>
          <div className="button_group">
            <button
              className={`button ${
                activeCategory === "Hanjogak" ? "active" : ""
              }`}
              onClick={() => {
                setActiveCategory("Hanjogak");
                setI18nActiveCategory("hanjogak");
              }}
            >
              Han jogak
            </button>
            <button
              className={`button ${
                activeCategory === "Wallet" ? "active" : ""
              }`}
              onClick={() => {
                setActiveCategory("Wallet");
                setI18nActiveCategory("wallet");
              }}
            >
              Wallet
            </button>
            <button
              className={`button ${
                activeCategory === "Trading" ? "active" : ""
              }`}
              onClick={() => {
                setActiveCategory("Trading");
                setI18nActiveCategory("trading");
              }}
            >
              Trading
            </button>
          </div>
          {data && (
            <div className="content_container">
              <img
                className="content_image"
                src={solutionData[i18nActiveCategory]?.image}
                alt={i18nActiveCategory}
                loading="lazy"
                decoding="async"
              />
              <div className="content_text_box">
                <div className="content_text">
                  <h3 className="content_title">
                    {data[i18nActiveCategory]?.title1}
                  </h3>
                  <p className="content_description_paragraph">
                    {data[i18nActiveCategory]?.description1}
                  </p>
                </div>
                <div className="content_text">
                  <h3 className="content_title">
                    {data[i18nActiveCategory]?.title2}
                  </h3>
                  <ul className="content_description_list">
                    <li>{data[i18nActiveCategory]?.list1}</li>
                    <li>{data[i18nActiveCategory]?.list2}</li>
                    <li>{data[i18nActiveCategory]?.list3}</li>
                    <li>{data[i18nActiveCategory]?.list4}</li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="app_box" id="app" ref={appBoxRef}>
        <img
          className="app_img"
          src={
            "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/6e92e80b-1b13-48db-cc44-578e725f1000/app"
          }
          alt="App Image"
          loading="lazy"
          decoding="async"
        />
        <div className="app_content_box">
          <div className={`app_title ${isAppVisible ? "visible" : ""}`}>
            {t("mainTranslation.app.title")}
          </div>
          <div className={`app_sub_title ${isAppVisible ? "visible" : ""}`}>
            {t("mainTranslation.app.content")}
          </div>
          <div className="app_btn_box">
            <button
              className={`app_btn ${isAppVisible ? "visible" : ""}`}
              onClick={() => startTransition(showAlert)}
              disabled={isPending}
            >
              <AppleSvg
                style={{
                  width: "1.2rem",
                  height: "1.32rem",
                }}
              />
              <div className="app_btn_text">App Store</div>
            </button>
            <button
              className={`app_btn ${isAppVisible ? "visible" : ""}`}
              onClick={() => startTransition(showAlert)}
              disabled={isPending}
            >
              <GoogleSvg
                style={{
                  width: "1.2rem",
                  height: "1.32rem",
                }}
              />
              <div className="app_btn_text">Google Play</div>
            </button>
          </div>
        </div>
      </section>
      <section className="content_box">
        <div className="content_inner">
          <h2 id="news" className="title">
            News
          </h2>
          <div className="news_box">
            {status === "loading"
              ? Array.from({ length: 3 }).map(() => <SkeletonNewsItem />)
              : newsList?.map((news, index) => (
                  <Link
                    key={news._id}
                    to={`/news/${news._id}`}
                    className="news_item"
                  >
                    <div className="news_content">
                      <div className="news_date">
                        <div>
                          <ElecBulbSvg
                            style={{
                              width: "1.69rem",
                              height: "1.69rem",
                            }}
                          />
                          <span>
                            {news.date.split(" ")[0].replace(/-/g, ".")}
                          </span>
                        </div>
                        <GoToSvg
                          style={{
                            width: "0.893rem",
                            height: "0.893rem",
                          }}
                        />
                      </div>
                      <span className="news_title">
                        {t(`news_${news._id}_title`)}
                      </span>
                      <div className="news_tag_list">
                        <span className="news_tag"># {news.tags}</span>
                      </div>
                    </div>
                    <div className="news_img_box">
                      <img
                        className={`news_prev_img ${index === 2 ? "edit" : ""}`}
                        src={newsImgs[index]}
                        alt="News Image"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </Link>
                ))}
          </div>
          <Link to="/news" className="goto_news_btn">
            <span> {t("newsTranslation.goNewsPage")}</span>
            <GoToNewsSvg
              style={{
                width: "0.56rem",
                height: "0.56rem",
                marginBottom: "0.4rem",
              }}
            />
          </Link>
        </div>
      </section>

      <section className="overlay_container" ref={overlayRef}>
        <img
          className="overlay_img"
          src={
            "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/f1afe2eb-f621-4663-24d4-061b704f3000/copy"
          }
          loading="lazy"
          decoding="async"
        />
        <span className="overlay_text">
          {t("mainTranslation.copyRight.content")}
        </span>
        <div
          className="left_overlay"
          style={{
            transform: `translate3d(${-overlayTranslate / 16}rem, 0, 0)`,
          }}
        ></div>
        <div
          className="right_overlay"
          style={{
            transform: `translate3d(${overlayTranslate / 16}rem, 0, 0)`,
          }}
        ></div>
      </section>

      <div className="last_content" ref={contactBoxRef} id="contact">
        <span className={`last_title ${contactVisible ? "visible" : ""}`}>
          {t("mainTranslation.contact.content1")}
        </span>
        <div className="last_sub_title">
          <span
            className={`last_sub_title_span ${
              contactVisible ? "visible delay_06" : ""
            }`}
          >
            {t("mainTranslation.contact.content2")}
          </span>
          <br />
          <span
            className={`last_sub_title_span ${
              contactVisible ? "visible delay_07" : ""
            }`}
          >
            {t("mainTranslation.contact.content3")}
          </span>
        </div>
        <Link
          to="/contact/#contactform"
          className={`inquiry_btn ${contactVisible ? "visible" : ""}`}
        >
          {t("mainTranslation.contactUsBtn")}
        </Link>
      </div>
    </div>
  );
}

export default MainContent;
