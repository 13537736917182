// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const enServiceResources = {
  serviceTranslation: {
    // 메인화면
    bannerExplain:
      "New services that you can't experience right now are coming soon.",
    content: {
      contentExplan:
        "We provide flexible and customized solutions and services with advanced technology and expertise.",
      solutionDetailTitle: "Detailed solution",
      serviceDetailTitle: "Detailed service",
    },
    // 솔루션상세
    solutionDetail: {
      solutionPageSubTitle:
        "Solutions specifically tailored and optimized for you",
      solution1: {
        solutionTitle: "Distributed Ledger Technology",
        solutionSubTitle: "",
        solutionContent: `Based on DLT (Distributed Ledger Technology), transactions and data are distributed and stored in an encrypted manner to maximize security. Smart Contracts enable programmable finance and provide a reliable, automatic execution process when investment conditions are met. Furthermore, we redefine the liquidity of existing assets through asset digitization (tokenization) and ensure efficiency and transparency at the same time by implementing a decentralized trading environment without intermediaries through DeFi (decentralized finance).`,
      },
      solution2: {
        solutionTitle: "AI",
        solutionSubTitle: "",
        solutionContent: `AI-based investment solutions learn non-linear patterns in market data through the latest Deep Learning and Reinforcement Learning algorithms and quantify the risks of complex financial products such as Security Token Offerings (STOs). This solution precisely assesses market volatility through Big Data analysis and predictive modeling, and provides personalized portfolio suggestions to help users make more strategic investment decisions.`,
      },
      solution3: {
        solutionTitle: "Automatically",
        solutionSubTitle: "",
        solutionContent: `Automated Solutions integrates with Wallet to automate users' transactions and asset management. This enables fast and safe transaction processing using DeFi protocols and smart contracts, saving time and maximizing reliability in advanced investment models such as STO. Additionally, we provide an optimized asset management system that simplifies complex financial transactions and maximizes users’ returns. This automation demonstrates the true stability and efficiency of decentralized financial systems.`,
      },
    },
    // 서비스상세
    serviceDetail: {
      servicePageSubTitle: "Customized and optimized service",
      service1: {
        serviceTitle: "Hanjokak",
        serviceSubTitle:
          "Medical Device · Hypercar · Work of Art · Real Estate",
        serviceContent: `Hanjokak is a new investment method that issues shares of real assets as tokens and shares them among multiple investors. Transaction history is managed using blockchain technology, allowing investors to buy/sell tokens, and has the advantage of being easily accessible even to small investors.`,
      },
      service2: {
        serviceTitle: "Wallet",
        serviceSubTitle: "",
        serviceContent: `Wallet completely secures users' digital assets based on DLT (distributed ledger technology) and provides a decentralized asset management environment through private keys. Wallet, which is seamlessly integrated with smart contracts and DeFi platforms, is linked to ReturnPlus' proprietary security system to minimize threats such as hacking and data loss, and thoroughly protects users' assets. It also serves as a powerful hub for interacting with all your digital assets with multi-chain support and improved user experience.`,
      },
      service3: {
        serviceTitle: "Trading",
        serviceSubTitle: "",
        serviceContent: `Distributed Ledger Technology (DLT) is a distributed ledger technology that shares and records data across multiple nodes without centralized management. Smart Contract is a contract code that is automatically executed when predefined conditions are met on the blockchain, and Decentralized Finance (DeFi) is financial applications and services that replace the traditional financial system in a decentralized manner. Commonly used. Lastly, tokenization is the process of converting real assets into digital tokens to increase the liquidity of the assets, and these technologies are used together.`,
      },
    },
  },
};

const koServiceResource = {
  serviceTranslation: {
    // 메인화면
    bannerExplain: "지금것 경험하지 못한 새로운 서비스가 곧 찾아옵니다.",
    content: {
      contentExplan:
        "앞선 기술과 전문성으로 유연하고 맞춤형 솔루션과 서비스를 제공합니다.",
      solutionDetailTitle: "상세솔루션",
      serviceDetailTitle: "상세서비스",
    },
    // 솔루션상세
    solutionDetail: {
      solutionPageSubTitle: "사용자 맞춤형으로 최적화된 솔루션",
      solution1: {
        solutionTitle: "Distributed Ledger Technology",
        solutionSubTitle: "분산원장기술",
        solutionContent: `DLT(Distributed Ledger Technology)에 기반하여 거래와 데이터를 암호화된 방식으로 분산 저장함으로써 보안을 극대화합니다. 스마트 계약(Smart Contract)은 프로그래머블 금융(Financial Programming)을 가능하게 하며, 투자 조건이 충족되면 신뢰할 수 있는 자동 실행 프로세스를 제공합니다. 더 나아가, 자산의 디지털화(Tokenization)를 통해 기존 자산의 유동성을 재정의하고, DeFi(탈중앙화 금융)를 통해 중개자 없는 탈중앙화된 거래 환경을 구현하여 효율성과 투명성을 동시에 보장합니다.`,
      },
      solution2: {
        solutionTitle: "AI",
        solutionSubTitle: "",
        solutionContent: `AI 기반 투자 솔루션은 최신 딥러닝(Deep Learning)과 강화학습(Reinforcement Learning) 알고리즘을 통해 시장 데이터의 비선형 패턴을 학습하며, STO(Security Token Offering)와 같은 복잡한 금융 상품의 위험을 정량화합니다. 이 솔루션은 빅데이터(Big Data) 분석 및 예측 모델링을 통해 시장 변동성을 정밀하게 평가하고, 사용자가 더 전략적인 투자 결정을 내릴 수 있도록 개인화된 포트폴리오 제안을 제공합니다.`,
      },
      solution3: {
        solutionTitle: "Automatically",
        solutionSubTitle: "자동화",
        solutionContent: `Automated Solutions은 Wallet과 연동하여 사용자의 거래와 자산 관리를 자동화합니다. 이를 통해 DeFi 프로토콜 및 스마트 계약을 활용한 빠르고 안전한 거래 처리가 가능하며, STO와 같은 고도화된 투자 모델에서 시간을 절약하고 신뢰성을 극대화합니다. 또한, 복잡한 금융 거래를 간소화하고 사용자의 수익률을 극대화하는 최적화된 자산 운용 시스템을 제공합니다. 이러한 자동화는 분산형 금융 시스템의 진정한 안정성과 효율성을 입증합니다.`,
      },
    },
    // 서비스상세
    serviceDetail: {
      servicePageSubTitle: "사용자 맞춤형으로 최적화된 서비스",
      service1: {
        serviceTitle: "한조각",
        serviceSubTitle: "의료기기 · 하이퍼카 · 미술품 · 부동산",
        serviceContent: `한조각은 실물 자산의 디지털 지분화를 통해 투자 접근성을 재정의합니다. 모든 거래는 블록체인 기반으로 기록되어 투명성과 불변성을 보장하며, 소액 투자자를 포함한 다양한 투자자들이 하나의 실물 자산에 대한 소유권을 공유할 수 있습니다. 한조각은 자산 관리의 효율성을 높이며, 토큰 매매 및 유동성 관리가 가능한 새로운 투자 생태계를 제시합니다.`,
      },
      service2: {
        serviceTitle: "Wallet",
        serviceSubTitle: "",
        serviceContent: `Wallet은 DLT(분산 원장 기술)를 기반으로 하여 사용자의 디지털 자산을 완벽히 보안하며, 프라이빗 키를 통해 탈중앙화된 자산 관리 환경을 제공합니다. 스마트 계약 및 DeFi 플랫폼과 원활하게 통합되는 Wallet은 ReturnPlus의 독자적인 보안 시스템과 연동되어 해킹, 데이터 손실 등의 위협을 최소화하며, 사용자의 자산을 철저히 보호합니다. 또한, 멀티체인 지원과 향상된 사용자 경험을 통해 모든 디지털 자산과 상호작용할 수 있는 강력한 허브 역할을 합니다.`,
      },
      service3: {
        serviceTitle: "Trading",
        serviceSubTitle: "",
        serviceContent: `Trading 플랫폼은 블록체인 기반의 STO(Security Token Offering)를 중심으로 설계된 혁신적인 금융 거래 시스템을 제공합니다. 글로벌 투자자들에게 실물 자산의 디지털 토큰화를 통해 국경 없는 거래를 가능하게 하며, 이를 통해 자산 거래의 효율성과 보안을 획기적으로 향상합니다. 차트 기반 AI 엔진은 시장 데이터를 실시간으로 분석하고, 맞춤형 투자 인사이트와 전략적 의사결정을 지원하여 사용자가 가장 수익성 높은 기회를 놓치지 않도록 도와줍니다.`,
      },
    },
  },
};

export { enServiceResources, koServiceResource };
