import { useMemo } from "react";
import { categories } from "../../util/data/categoris";
import NotFound from "../../common/NotFound/NotFound";

function BannerBridge({ category }) {
  const categoryData = useMemo(
    () => categories.find((cat) => cat.category === category),
    [category]
  );
  const BannerComponent = categoryData ? categoryData.Banner : null;

  return BannerComponent ? (
      <BannerComponent />
  ) : (
    <NotFound />
  );
}

export default BannerBridge;
