function SkeletonNewsItem() {
  return (
    <div className="news_item skeleton">
      <div className="news_content">
        <div className="news_date">
          <div>
            <div className="skeleton_icon"></div>
            <div className="skeleton_text small"></div>
          </div>
          <div className="skeleton_icon_small"></div>
        </div>
        <div className="news_title skeleton_text large"></div>
        <div className="news_tag_list">
          <div className="skeleton_tag"></div>
        </div>
      </div>
      <div className="news_img_box">
        <div className="skeleton_image"></div>
      </div>
    </div>
  );
}

export default SkeletonNewsItem;
