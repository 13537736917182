// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  enServiceResources as enService_i18n,
  koServiceResource as koService_i18n,
} from "./util/i18n/service_i18n.js";
import { company_i18n } from "./util/i18n/company/company_i18n.js";
import { contact_i18n } from "./util/i18n/contact/contact_i18n.js";
import { resources as news_i18n } from "./util/i18n/news/news_i18n.js";
import { resources as main_i18n } from "./util/i18n/main/main_i18n.js";
import { resources as footer_i18n } from "./util/i18n/footer/footer_i18n.js";
import { alert_i18n } from "./util/i18n/alert/alert_i18n.js";

const resources = {
  en: {
    translation: {
      ...enService_i18n,
      ...company_i18n.en,
      ...contact_i18n.en,
      ...news_i18n.en,
      ...main_i18n.en,
      ...footer_i18n.en,
      ...alert_i18n.en,
    },
  },
  ko: {
    translation: {
      ...koService_i18n,
      ...company_i18n.ko,
      ...contact_i18n.ko,
      ...news_i18n.ko,
      ...main_i18n.ko,
      ...footer_i18n.ko,
      ...alert_i18n.ko,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ko",
  fallbackLng: "ko",
  interpolation: {
    escapeValue: false,
  },
});

export const updateI18nNews = (data) => {
  const enTranslations = {};
  const koTranslations = {};

  data.forEach((news) => {
    enTranslations[`news_${news._id}_title`] = news.title_en;
    enTranslations[`news_${news._id}_content`] = news.content_en;

    koTranslations[`news_${news._id}_title`] = news.title;
    koTranslations[`news_${news._id}_content`] = news.content;
  });

  i18n.addResources("en", "translation", enTranslations);
  i18n.addResources("ko", "translation", koTranslations);
};

export const updateI18nPost = (data) => {
  const enTranslations = {};
  const koTranslations = {};

  data.forEach((post) => {
    enTranslations[`post_${post.to}_title`] = post.title_en;
    enTranslations[`post_${post.to}_tag`] = post.tag_en[0];
    enTranslations[`post_${post.to}_content`] = post.content_en;

    koTranslations[`post_${post.to}_title`] = post.title;
    koTranslations[`post_${post.to}_tag`] = post.tag[0];
    koTranslations[`post_${post.to}_content`] = post.content;
  });

  i18n.addResources("en", "translation", enTranslations);
  i18n.addResources("ko", "translation", koTranslations);
};

export default i18n;
