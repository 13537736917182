import { ReactComponent as DownSvg } from "../../assets/images/메인_다운로드.svg";
import { ReactComponent as InquirySvg } from "../../assets/images/메인_문의하기_화살표.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const splitTextToLetters = (text, lineDelay = 0) => {
  return text.split("").map((char, index) => {
    if (char === " ") {
      return <span key={index}>&nbsp;</span>;
    }
    return (
      <span
        key={index}
        className="letter"
        style={{ animationDelay: `${lineDelay + index * 0.05}s` }}
      >
        {char}
      </span>
    );
  });
};

const AnimatedDescriptLine = ({ text, lineDelay }) => {
  return (
    <span
      className="descript_animated_line"
      style={{ animationDelay: `${lineDelay}s` }}
    >
      {text}
    </span>
  );
};

function MainBanner() {
  const { t } = useTranslation();
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const maxScroll = 800;
      const newOpacity = Math.max(1 - scrollY / maxScroll, 0);
      setOpacity(newOpacity);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="main_banner" style={{ opacity }}>
      <img
        src={
          "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/5a1b308d-9f99-426f-1563-1bed983d8600/return"
        }
        alt="Main Banner"
        loading="lazy"
        decoding="async"
        className="bg_img"
      />
      <div className="content_box">
        <h1 className="title">
          <div className="hidden_box">
            <span className="title_line">
              {splitTextToLetters("Return", 0.3)}
            </span>
          </div>
          <div className="hidden_box">
            <span className="title_line">
              {splitTextToLetters("Plus", 0.5)}
            </span>
          </div>
        </h1>
        <p className="descript">
          <div className="line">
            <div className="hidden_box">
              <AnimatedDescriptLine
                text={t("mainTranslation.banner.text1")}
                lineDelay={0.4}
              />
            </div>
            <div className="hidden_box">
              <AnimatedDescriptLine
                text={t("mainTranslation.banner.text2")}
                lineDelay={0.5}
              />
            </div>
          </div>
          <div className="line">
            <div className="hidden_box">
              <AnimatedDescriptLine
                text={t("mainTranslation.banner.text3")}
                lineDelay={0.6}
              />
            </div>
            <div className="hidden_box">
              <AnimatedDescriptLine
                text={t("mainTranslation.banner.text4")}
                lineDelay={0.7}
              />
            </div>
            <div className="hidden_box">
              <AnimatedDescriptLine
                text={t("mainTranslation.banner.text5")}
                lineDelay={0.8}
              />
            </div>
          </div>
        </p>
        <div className="btn_box">
          <div className="hidden_box">
            <Link
              to="/contact/#contactform"
              className="btn"
              style={{ animationDelay: "1s" }}
            >
              <span>{t("mainTranslation.contactUsBtn")}</span>
              <InquirySvg
                style={{
                  width: "1.375rem",
                  height: "1.25em",
                  fill: "#fff",
                  transition: "fill 0.2s",
                }}
              />
            </Link>
          </div>
          <div className="hidden_box">
            <Link
              to="/company/#irdown"
              className="btn"
              style={{ animationDelay: "1.1s" }}
            >
              <span>{t("mainTranslation.banner.irDownload")}</span>
              <DownSvg
                style={{
                  width: "1.375rem",
                  height: "1.5625rem",
                  fill: "#fff",
                  transition: "fill 0.2s",
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;
