import { useTransition } from "react";
import { useTranslation } from "react-i18next";

export const useNotReadyAlert = () => {
  const { t } = useTranslation();
  const [isPending, startTransition] = useTransition();

  const showAlert = () => {
    startTransition(() => {
      alert(t("preparing"));
    });
  };
  return { showAlert, isPending, startTransition };
};
