import BannerBridge from "../../components/Banners/BannerBridge";
import ContentBridge from "../../components/Contents/ContentBridge";

function Service() {
  return (
    <div className="service_container">
      <BannerBridge category="service" />
      <ContentBridge category="service" />
    </div>
  );
}
export default Service;
