import { useState, useEffect } from "react";

const useIntersection = (ref, threshold = 0.1) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const observerOptions = {
      root: null, // viewport
      rootMargin: "0px",
      threshold: threshold,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.disconnect(); 
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(ref.current);

    return () => {
      if (observer && observer.disconnect) observer.disconnect();
    };
  }, [ref, threshold]);

  return isIntersecting;
};

export default useIntersection;