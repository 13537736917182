import BannerBridge from "../../components/Banners/BannerBridge";
import ContentBridge from "../../components/Contents/ContentBridge";

function News() {
  return (
    <div className="news_container">
      <BannerBridge category="news" />
      <ContentBridge category="news" />
    </div>
  );
}
export default News;
