import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import visionImg from "../../assets/images/컴퍼니_비전.avif";
import talent1Img from "../../assets/images/컴퍼니_인재상.avif";
import talent2Img from "../../assets/images/컴퍼니_인재상2.avif";
import { companySvgList } from "../../util/data/Company/companySvgList";
import { companyHistoryData } from "../../util/data/Company/companyHistoryData";
import IRDownload from "../../components/CompanyAndContact/IRDownload";
import useHashScroll from "../../hooks/useHashScroll";

function CompanyContent() {
  const { t } = useTranslation();
  useHashScroll();
  const [selectedYear, setSelectedYear] = useState("2024");

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  return (
    <div className="company_wrapper">
      <section className="mission_section" id="vision">
        <div className="section_inner">
          <div className="mission_part">
            <span className="mission_title">RETURN PLUS</span>
            <span className="mission_heading">
              {t("CompanyContents.CoreValues.Mission.Title")}
            </span>
          </div>
          <div className="mission_part">
            <span className="mission_content">
              {t("CompanyContents.CoreValues.Mission.ContentFirst")}
            </span>
            <span className="mission_content">
              <span className="bold">
                {t("CompanyContents.CoreValues.Mission.ContentAccent")}{" "}
              </span>
              {t("CompanyContents.CoreValues.Mission.ContentSecond")}
            </span>
          </div>
        </div>
      </section>
      <section
        className="vision_section"
        style={{ backgroundImage: `url(${visionImg})` }}
      >
        <div className="section_inner">
          <div className="vision_part">
            <span className="vision_title">RETURN PLUS</span>
            <span className="vision_heading">
              {t("CompanyContents.CoreValues.Vision.Title")}
            </span>
          </div>
          <div className="vision_part">
            <span className="vision_content">
              {t("CompanyContents.CoreValues.Vision.ContentFirst")}
            </span>
            <span className="vision_content">Leader of Investment Future</span>
          </div>
        </div>
      </section>
      <section className="slogan_section">
        <div className="section_inner">
          <div className="slogan_part">
            <span className="slogan_title">RETURN PLUS</span>
            <span className="slogan_heading">
              {t("CompanyContents.CoreValues.Slogan.Title")}
            </span>
          </div>
          <div className="slogan_part">
            <span className="slogan_content">
              <span className="bold">
                {t("CompanyContents.CoreValues.Slogan.ContentAccent")}
              </span>
              {t("CompanyContents.CoreValues.Slogan.ContentFirst")}
            </span>
            <div className="slogans">
              {companySvgList &&
                companySvgList.map((slogan, index) => (
                  <div key={index} className="slogan_item">
                    <span className="slogan_icon">
                      {slogan.icon("2.783rem")}
                    </span>
                    <span className="slogan_name">{t(slogan.name)}</span>
                    <span className="slogan_description">
                      {t(slogan.descriptions)}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section className="history_section" id="history">
        <div className="section_history_inner">
          <span className="history_title">History</span>
          <div className="history_content_box">
            <div className="history_header">
              <span className="history_subtitle">
                {t("CompanyContents.History.SubTitle")}
              </span>
            </div>
            <div className="history_year_selector">
              {Object.keys(companyHistoryData).map((year) => (
                <span
                  key={year}
                  className={`history_year ${
                    selectedYear === year ? "active" : ""
                  }`}
                  onClick={() => handleYearClick(year)}
                >
                  {year}
                </span>
              ))}
            </div>
            <div className="history_details">
              <span className="history_year_title">{selectedYear}</span>
              {companyHistoryData[selectedYear].map((entry, index) => {
                const translatedContent = t(entry.content, {
                  returnObjects: true,
                });
                return (
                  <React.Fragment key={index}>
                    <span className="history_month">{`${selectedYear}.${entry.month}`}</span>
                    {translatedContent &&
                      translatedContent.map((desc, idx) => (
                        <span
                          key={idx}
                          className={`history_description ${
                            entry.description === "Title" ? "title" : ""
                          }`}
                        >
                          {t(desc)}
                        </span>
                      ))}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section
        className="talent1_section"
        style={{
          backgroundImage: `url(${talent1Img})`,
        }}
      ></section>
      <section className="talent2_section" id="talent">
        <div
          className="talent_image"
          style={{
            backgroundImage: `url(${talent2Img})`,
          }}
        ></div>
      </section>
      <IRDownload />
    </div>
  );
}

export default CompanyContent;
