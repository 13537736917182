import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect";

function ContactForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    inquiryType: "",
    email: "",
    emailId: "",
    emailDomain: "",
    customDomain: "",
    name: "",
    phone: "",
    inquiryTitle: "",
    message: "",
    agree1: false,
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);
      updatedData.customDomain =
        updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : "";
      return updatedData;
    });
  };

  const handleEmailDomainChange = (value) => {
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        emailDomain: value,
      };

      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);
      updatedData.customDomain =
        updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : "";
      return updatedData;
    });
  };

  const handleAgreeClick = (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      agree1: !prev.agree1,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.inquiryType) {
      alert(t("ContactContents.ContactUs.Form.Alert.inquiryType"));
      return;
    } else if (!formData.name) {
      alert(t("ContactContents.ContactUs.Form.Alert.name"));
      return;
    } else if (!formData.emailId) {
      alert(t("ContactContents.ContactUs.Form.Alert.emailId"));
      return;
    } else if (!formData.phone) {
      alert(t("ContactContents.ContactUs.Form.Alert.phone"));
      return;
    } else if (!formData.inquiryTitle) {
      alert(t("ContactContents.ContactUs.Form.Alert.inquiryTitle"));
      return;
    } else if (!formData.message) {
      alert(t("ContactContents.ContactUs.Form.Alert.Message"));
      return;
    } else if (!formData.agree1) {
      alert(t("ContactContents.ContactUs.Form.Alert.agree1"));
      return;
    }
    if (
      formData.emailDomain === "직접입력" ||
      formData.emailDomain === "Enter Email Domain"
    ) {
      if (!formData.customDomain) {
        alert(t("ContactContents.ContactUs.Form.Alert.customDomain"));
        return;
      }
    } else if (!formData.emailDomain) {
      alert(t("ContactContents.ContactUs.Form.Alert.emailDomain"));
      return;
    }
    var today = new Date();

    var year = today.getFullYear();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    var dateString = year + "-" + month + "-" + day;
    var hours = ("0" + today.getHours()).slice(-2);
    var minutes = ("0" + today.getMinutes()).slice(-2);
    var seconds = ("0" + today.getSeconds()).slice(-2);
    var timeString = hours + ":" + minutes + ":" + seconds;

    const formDataToSubmit = {
      문의시간: dateString + " " + timeString,
      이메일: formData.email,
      이름: formData.name,
      휴대폰번호: formData.phone,
      문의분류: formData.inquiryType,
      문의제목: formData.inquiryTitle,
      문의내용: formData.message,
      개인정보수집동의: formData.agree1,
    };
    const dataToSubmit = new FormData();
    for (const key in formDataToSubmit) {
      dataToSubmit.append(key, formDataToSubmit[key]);
    }
    fetch(process.env.REACT_APP_CONTACT_APPSCRIPTS_URL, {
      method: "POST",
      body: dataToSubmit,
    }).then((response) => {
      if (response.ok) {
        alert(t("ContactContents.ContactUs.Form.Alert.SuccessResultMessage"));
      } else {
        alert(t("ContactContents.ContactUs.Form.Alert.FailResultMessage"));
      }
    });
  };

  return (
    <div className="contactform_form_wrapper">
      <div className="contactform_form_box" onSubmit={handleSubmit}>
        <div className="contactform_title_box">
          <span className="contactform_title" id="contactform">
            Contact Us
          </span>
          <span className="contactform_sub_title">
            {t("ContactContents.ContactUs.SubTitle")}
          </span>
        </div>
        <form>
          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.contactType")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box">
              <CustomSelect
                options={t(
                  "ContactContents.ContactUs.Form.contactTypeSelectBox",
                  {
                    returnObjects: true,
                  }
                )}
                onChange={(value) =>
                  setFormData({ ...formData, inquiryType: value })
                }
                required
              />
            </div>
          </div>

          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.name")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box contactform_input_box_border">
              <input
                type="text"
                name="name"
                onChange={handleChange}
                required
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.input"
                )}
                className="contactform_input"
              />
            </div>
          </div>

          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.email")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_email_input_box">
              <input
                type="text"
                name="emailId"
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.input"
                )}
                onChange={handleChange}
                required
                className="contactform_email_input"
              />
              <div className="contactform_email_domain_sign_box">
                <span className="contactform_email_span">@</span>
              </div>
              <div className="contactform_email_input_inner">
                <input
                  name="customDomain"
                  placeholder={
                    formData.emailDomain === "직접입력" ||
                    formData.emailDomain === "Enter Email Domain"
                      ? t("ContactContents.ContactUs.Form.placeholder.input")
                      : t("ContactContents.ContactUs.Form.placeholder.category")
                  }
                  value={
                    formData.emailDomain === "직접입력" ||
                    formData.emailDomain === "Enter Email Domain"
                      ? formData.customDomain
                      : formData.emailDomain
                  }
                  disabled={
                    formData.emailDomain !== "직접입력" &&
                    formData.emailDomain !== "Enter Email Domain"
                  }
                  onChange={handleChange}
                  required
                  className="contactform_email_input_current"
                />
                <div className="contactform_custom_select_box">
                  <CustomSelect
                    options={t(
                      "ContactContents.ContactUs.Form.domainSelectBox",
                      {
                        returnObjects: true,
                      }
                    )}
                    onChange={handleEmailDomainChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.phone")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box contactform_input_box_border">
              <input
                type="text"
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.phone"
                )}
                name="phone"
                onChange={handleChange}
                maxLength="12"
                minLength="9"
                required
                className="contactform_input"
              />
            </div>
          </div>

          <div className="contactform_form_inner">
            <div className="contactform_label_box">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.contactTitle")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_input_box contactform_input_box_border">
              <input
                type="text"
                name="inquiryTitle"
                maxLength="20"
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.contactTitle"
                )}
                onChange={handleChange}
                required
                className="contactform_input"
              />
            </div>
          </div>

          <div className="contactform_form_inner contactform_form_inner_textarea">
            <div className="contactform_label_box contactform_label_box_textarea">
              <label className="contactform_label">
                {t("ContactContents.ContactUs.Form.contactContents")}
                <span className="contactform_label_span"> *</span>
              </label>
            </div>
            <div className="contactform_text_area_inner">
              <textarea
                name="message"
                placeholder={t(
                  "ContactContents.ContactUs.Form.placeholder.contactContents"
                )}
                rows="5"
                onChange={handleChange}
                required
                maxLength={1000}
                className="contactform_text_area"
              />
              <span className="contactform_char_count">
                {formData.message.length}/1000
              </span>
            </div>
          </div>

          <div className="contactform_checkbox_wrapper">
            <div className="contactform_checkbox_box">
              <input
                type="checkbox"
                name="agree1"
                onChange={handleChange}
                checked={formData.agree1}
                className="contactform_checkbox"
                id="contactform_agree1"
              />
              <div
                className={`contactform_custom_checkbox ${
                  formData.agree1 ? "contactform_checked" : ""
                }`}
                onClick={handleAgreeClick}
              ></div>
              <label
                htmlFor="contactform_agree1"
                className="contactform_checkbox_label"
                onClick={handleAgreeClick}
              >
                {t("ContactContents.ContactUs.Form.privacyPolicy.Title")}
              </label>
            </div>
            <p className="contactform_privacy_policy_content contactform_privacy_policy_content_subtitle">
              {t("ContactContents.ContactUs.Form.privacyPolicy.SubTitle")}
            </p>
            <p className="contactform_privacy_policy_content contactform_privacy_policy_content_content_title">
              {t("ContactContents.ContactUs.Form.privacyPolicy.ContentTitle")}
            </p>
            <div className="contactform_privacy_policy_grid">
              <div className="contactform_privacy_policy_grid_item contactform_privacy_policy_grid_item_collect_item">
                {t(
                  "ContactContents.ContactUs.Form.privacyPolicy.Grid.CollectItem"
                )}
              </div>
              <div className="contactform_privacy_policy_grid_item contactform_privacy_policy_grid_item_collect_purpose">
                {t(
                  "ContactContents.ContactUs.Form.privacyPolicy.Grid.CollectPurpose"
                )}
              </div>
              <div className="contactform_privacy_policy_grid_item contactform_privacy_policy_grid_item_collect_term">
                {t(
                  "ContactContents.ContactUs.Form.privacyPolicy.Grid.CollectTerm"
                )}
              </div>

              <div className="contactform_privacy_policy_grid_item contactform_privacy_policy_grid_item_collect_item_content">
                {t(
                  "ContactContents.ContactUs.Form.privacyPolicy.Grid.CollectItemContent"
                )}
              </div>
              <div className="contactform_privacy_policy_grid_item contactform_privacy_policy_grid_item_collect_purpose_content">
                {t(
                  "ContactContents.ContactUs.Form.privacyPolicy.Grid.CollectPurposeContent"
                )}
              </div>
              <div className="contactform_privacy_policy_grid_item contactform_privacy_policy_grid_item_collect_term_content">
                {t(
                  "ContactContents.ContactUs.Form.privacyPolicy.Grid.CollectTermContent"
                )}
              </div>
            </div>
            <p className="contactform_privacy_policy_content contactform_privacy_policy_content_warning">
              {t("ContactContents.ContactUs.Form.privacyPolicy.Warning")}
            </p>
          </div>

          <div className="contactform_button_box">
            <button
              type="submit"
              className="contactform_submit_button"
              onClick={handleSubmit}
            >
              {t("ContactContents.ContactUs.Form.Button")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
